<template>
  <div>
    <!-- 顶部 -->
    <van-sticky>
      <div class="title_div">盘点</div>
    </van-sticky>
    
    <!-- 总部全盘  门店全盘 -->
     <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;"  @click="stock_talking(0)">
      <img src="../assets/pandain//mipmap-hdpi/zongbuquanpan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">总部全盘</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Headquarters inventory</div>
      </div>
    </div>
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;"  @click="stock_talking(1)">
      <img src="../assets/pandain//mipmap-hdpi/mendianquanpan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">门店全盘</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Store inventory</div>
      </div>
    </div>
    </div>
   <!-- 动销盘  总部抽盘 -->
    <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;"  @click="stock_talking(2)">
      <img src="../assets/pandain//mipmap-hdpi/dongxiaopan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">动销盘</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Movable pin plate</div>
      </div>
    </div>
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;"  @click="stock_talking(3)">
      <img src="../assets/pandain//mipmap-hdpi/zongbuchoupan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">总部抽盘</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Head office drawing</div>
      </div>
    </div>
    </div>
    <!-- 动态抽盘 即时抽盘 -->
    <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;"  @click="stock_talking(4)">
      <img src="../assets/pandain//mipmap-hdpi/dongtaichoupan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">动态抽盘</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Dynamic takeout</div>
      </div>
    </div>
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;"  @click="stock_talking(5)">
      <img src="../assets/pandain//mipmap-hdpi/jishipandian.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">即时盘点</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Real time inventory</div>
      </div>
    </div>
    </div>
    <!-- 营业助手 -->
    <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;" @click="helper()">
      <img src="../assets/pandain//mipmap-hdpi/yingyezhushou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">营业助手</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Business Assistant</div>
      </div>
    </div>
    <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;" @click="collectList()">
      <img src="../assets/icon/caigou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">采价</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Price collection</div>
      </div>
    </div>
    </div>
    <!-- 码上放心 -->
    <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;" @click="atOnce()">
      <img src="../assets/pandain//mipmap-hdpi/dongtaichoupan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">码上放心</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Business Assistant</div>
      </div>
    </div>
    <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;" @click="creditCard()">
      <img src="../assets/pandain//mipmap-hdpi/dongxiaopan.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">医保刷卡</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Credit card for medical</div>
      </div>
    </div>
    </div>
    
    <!-- <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
 
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;" @click="arehousingScanning(1,tmpBarCode)">
      <img src="../assets/pandain//mipmap-hdpi/yingyezhushou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">入库扫描</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Warehousing Scanning </div>
      </div>
    </div>

    <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;" @click="outboundScanning(1,barCodeOutScanchuku)">
      <img src="../assets/icon/caigou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">出库扫描</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Price collection</div>
      </div>
    </div>
    </div>
    <div style="width:94%;height:26vw;margin-left: 3%;margin-top: 2vw;display: flex;justify-content: space-between;align-items: center;">
    
     <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;" @click="testDiff()">
      <img src="../assets/pandain//mipmap-hdpi/yingyezhushou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">入库差异</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Warehousing Scanning </div>
      </div>
    </div>
   
    <img src="../assets/pandain/mipmap-hdpi/矩形 4 拷贝 17.png" alt="" style="width:50%;height: 25vw;position: relative;">
     <div style="position:absolute;left:4vw;display: flex;justify-content: space-around;align-items: center;margin-left:47vw;" @click="testDiffOut()" >
      <img src="../assets/icon/caigou.png" alt="" style="width:13vw;height:15vw;margin-left: 1vw;">
      <div style="font-size:3vw;margin-left:2vw;">
        <div style="color:#000;font-size: 6.5vw;">出库差异</div>
        <div style="color:#676767;transform: scale(0.8);margin-left: -3vw;">Price collection</div>
      </div>
    </div>
    </div> -->
    <!-- itemsList -->
    <!-- <div class="my" style=" display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin-top: 3vh; padding: 7vw 0;">
      <div v-for="(item, index) in itemsList" :key="index" class="order_item" style="width: 23vw; font-size: 2vw; margin-top: -3vw" @click="stock_talking(index)">
        <img :src="item.img" style="height: 11.6vw" />
        <div style="margin-top: 1vh; margin-bottom: 3vh;font-size:4vw;font-weight: bolder;">{{ item.name }}</div>
      </div>
    </div> -->
    <!-- 商品助手 -->
    <!-- <div class="my1" @click="helper()" style="display: flex;flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 5vw 0;">
     
      <div style="display:flex;align-items: center;margin-right:22vw;">
        <div class="help" style="display:flex;justify-content: center;align-items: center;" ><img src="../assets/icon/矢量智能对象.png" alt="" style="width:9vw;height:9vw"></div>
       <div style="font-size:4.6vw;margin-left: 4vw;">营业助手</div>
      </div>

      <div style="margin-left:6vw">
        <img src="../assets/icon/31fanhui1.png" alt="" style="width:2vw;height:3.5vw">
      </div>
  
    </div> -->
    <!--底部tab栏-->
    <Tabbar
      :choose_index="2"
      :user_type2="user_type2"
      :type="pagetype"
      :quanxiann ="quanxain"
    ></Tabbar>
  </div>
</template>
  <script>
import Tabbar from "@/components/Tabbar";
import wx from "weixin-js-sdk";
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      quanxain : 1,
      type:0,
      pointList:[],
      user_type2: false, //是否是区域看板
      pagetype: 0, //1总部看板
      loading:'',
      tmpBarCode:"",
      barCodeOutScanchuku:""
    };
  },
  created() {
    this.tmpBarCode=this.$route.params.barCode1;
    this.barCodeOutScanchuku=this.$route.params.barCodeOutScan
    this.showLoading()
    this.inventoryList()
    this.user_api()
  },
  methods: {
    creditCard(){
      this.$router.push("./test")
    },
    atOnce(){
      this.$router.push("./atOnce")
    },
    collectList(){
      this.$router.push("./priceCollect")
    },
    user_api(){
      
      this.$axios.post(this.$api.user_api).then(res=>{   
        localStorage.setItem("user_info", JSON.stringify(res.data.data));
       this.roleId=res.data.data.roleId
       this.roleId.forEach(element => {
          if(this.roleId.length===1 && element===3){
           console.log(element,"element")
           this.quanxain=element
          }
          else{
            this.quanxain=1
          }
          
       });
  
       console.log(this.roleId,"this.roleId")
      })
    },
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
  // 商品助手跳转到搜索信息页面
  helper(){
    this.$router.push("./helper")
  },
  //  盘点页面 分别跳转
    stock_talking(index,path){
    console.log(index,"ass")
    // console.log(path,"aaaaaaaaa")
    this.inventoryList(index)
    this.$router.push(`./point_style/${index}`)
  },
   //inventoryList
   inventoryList(ind){
      this.$axios.get(this.$api.inventoryList,{
        params:{
          type:ind,
          limit:this.page.pageSize,
          page:this.page.currentPage,
        }
      }).then(res=>{
        this.loading.close();
        this.pointList=res.data.page.list
        console.log(this.pointList,"inventoryList")
        this.pointList =res.data.page.list
        
      })
    },
  
  }
};
</script>
  <style scoped>
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  line-height: 12vh;
  color: #ffffff;
  text-align: center;
  font-size: 4.6vw;
}
.every_option {
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
}
.search_input {
  outline: none;
  border: none;
  width: 40vw;
  border-bottom: 1px solid #e7e6e6;
  font-size: 14px;
  padding-left: 10px;
}
.my {
  width: 94vw;
  height: 37vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/2.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  padding: 5vw 0;
 
}
.my1{
  width: 94vw;
  height: 7vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/2.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  padding: 5vw 0;
}
.order_item {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  
}
.help{
  background: url(../assets/icon/矩形\ 1.png) no-repeat;
  width: 12vw;
  height: 12vw;
  background-size: 100% 100%;
}
</style>